// App.js
import React from "react";
import Signup from "./signup";
import LandingPage from "./landingPage";

const App = () => {
  return (
    <div>
      <Signup />
    </div>
  );
};

export default App;